<template>
    <div class="flex aic form-item" @click="visible = !visible">
        <el-input v-model="selected.name" class="flex-item" readonly></el-input>
        <el-button style="margin-left: 10px">选择</el-button>
    </div>
    <el-dialog v-model="visible" title="选择卡片" width="800px">
        <ATable :url="tableUrl" :columns="columns" @current-change="CurrentChange" ref="table"/>
    </el-dialog>
</template>


<script>
import ATable from '@/components/table/index'
import { ref } from 'vue'
import { list } from '@/api/card'

export default {
    components: {ATable},
    emits: ['change'],
    setup (props, ctx) {
        const tableUrl = ref(list)
        const columns = ref([
            {prop: 'name', label: '卡片名称'},
            {prop: 'icon', label: '图标', type: 'image'},
            {prop: 'content', label: '详情'},
            {prop: 'type', label: '类型', render: text =>{
                let label = {1: '明信片', 2: '知识卡'}
                return label[text] || '无'
            }, width: 100},
            {prop: 'attribute', label: '属性', render: text => {
                let label = {1: '普通卡片', 2: '稀有卡片'}
                return label[text] || '无'
            }, width: 100},
            {prop: 'shortIcon', label: '缩略图', type: 'image'},

        ])
        
        let visible = ref(false)
        let selected = ref({})

        const methods = {
            UpdatedSelected (value) {
                console.log(value)
                selected.value = value
            },
            CurrentChange (value) {
                selected.value = value
                visible.value = false
                ctx.emit('change', selected.value)
            },
        }

        return {
            selected,
            visible,
            tableUrl,
            columns,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>
.form-item{
    cursor: pointer;
    width: 100%;
}
</style>